import logo from "./logo.svg";
import "./App.css";
import GameCard from "./components/gameCard"

function App() {

  let queryParams = new URLSearchParams(window.location.search)

  alert(queryParams)

  return (
    <div className="App">
      <header className="Toolbar">
        <img src={logo} className="Logo" alt="logo"/>
      </header>
      <div className="Body">
        <GameCard />
      </div>
    </div>
  );
}

export default App;
