import React, { Component } from "react";
import { Card } from "antd";
import { gameData } from "../data";

const { Meta } = Card;

class GameCard extends Component {

    sayHello = (url) => {
        alert(url);
    }

    render() {
        return (
            <div className="Horizontal-Scroll">
            {gameData.map((value, key) => {
                console.log(value);
                return (
                    <Card
                hoverable
                style={{ width: 240 }}
                cover={
                  <img
                    alt="example"
                    src={value.image}
                    style={{height: 200}}
                  />
                }
                className="Game-Card"
                // onClick={this.sayHello(value.url)}
              >
                <Meta title="Europe Street beat" description="www.instagram.com" />
              </Card>
                )
            })}
            </div>
            
        )
    }
}

export default GameCard